import React, { FC } from 'react'
import InnerContainerPage from 'components/InnerContainerPage'
import Head from 'next/head'
import HeaderSection from '../components/HeaderSection'
import Heading from 'components/Heading'
import Paragraph from 'components/Paragraph'
import Link from 'next/link'
import Image from 'next/image'

const NotFound: FC = () => {
  return (
    <>
      <Head>
        <title>In The Mirror: 404 Page Not Found </title>
      </Head>

      <InnerContainerPage>
        <HeaderSection>
          <Heading size="2">Not Found</Heading>
        </HeaderSection>
        <div className="bg-cardBg w-3/4 h-full rounded-xl p-12 my-5">
          <p className="text-lg font-semibold text-white font-primary mb-4">
            Hmm, no treatos here. Double check the link?
          </p>
          <p className="text-lg text-white font-primary">
            If you keep running into this,
            <Link href="https://discord.com/invite/the-mirror-open-source-roblox-uefn-alternative-900537135998840852">
              <a className="underline"> post on #bugs on Discord </a>
            </Link>
            and we’ll help you out!
          </p>
          <div className="flex mt-10 justify-center items-center">
            <Image
              width={500}
              height={500}
              objectFit="contain"
              unoptimized
              src={require('../public/images/404page.png')}
              alt="404"
            />
          </div>
        </div>
      </InnerContainerPage>
    </>
  )
}

NotFound['Layout'] = 'User'

export default NotFound
